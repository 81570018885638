import React from "react"
import { Link } from "gatsby"
import BodyClassName from "react-body-classname"
import "../less/app.less"

const NotFoundPage = () => (
  <BodyClassName className="notfound">
    <div className="app">
      <div className="missing">
        <h1>404</h1>
        <p>
          <span>Go back to </span>
          <Link className="link">gild.fi</Link>
        </p>
      </div>
    </div>
  </BodyClassName>
)

export default NotFoundPage
